import config from '/config';

// Deps
import { useState, useEffect, useRef, useCallback } from 'react'

// Sections
import Breadcrumbs from '/views/sections/breadcrumbs'
import Listing from '/views/sections/listing'
import ProductCarousel from '/views/sections/product-carousel'

// Functions
import isExact from '@wearetla/tla-essentials-tools/functions/is-exact';
import parseQuery from '/functions/parse-query';

// Hooks
import useQuery from '/hooks/query'

// Services
import productServices from '/services/product'
import siteServices from '/services/site'

// Context
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state'
import { useNavigator, useParams } from '@wearetla/tla-essentials-tools/utilities/navigator'
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useChildren } from '/utilities/children/use-children';
import { useGlobalEvents } from '@wearetla/tla-essentials-tools/utilities/global-events';

// Shared Endpoints
const getCategoryProducts = (categoryID, query) => {
	return productServices.getCategoryProducts(categoryID, query);
}

const getCategoryFilters = (categoryID, inStock) => {
	return productServices.getCategoryFilters(categoryID, inStock);
}

const getCategoryBanners = (categoryID) => {
	return productServices.getCategoryBanners(categoryID);
}

const getRecentProducts = () => {
	return siteServices.getLastVisitedProducts();
}

const getCategorySchema = (categoryID) => {
	return siteServices.getSchema('category', categoryID)
}

const generateHead = (activeCategory, pagination, categorySchema) => {
	return {
		title: activeCategory.seo_title ? activeCategory.seo_title : activeCategory.name,
		description: activeCategory.seo_description ? activeCategory.seo_description : activeCategory.description,
		subtitle: pagination.total,
		// breadcrumbs: activeCategory.breadcrumb,
		schema: categorySchema && categorySchema.length > 0 ? categorySchema.map(schema => schema.description) : false,
		canonical: '' //getPageURL('category', { id: activeCategory.id, slug: activeCategory.slug }),
	}
}

const defaultQuery = {
	page: '1',
	order: 'ovd',
	// limit: 20,
	// stock: '1',
}

const Category = ({ pageProps, match }) => {
	const _mounted = useRef(false);
	
	const { setError, setNotFound } = useGlobalState();
	const { childSelection } = useChildren();
	const { redirect } = useNavigator();
	const { triggerEvent } = useGlobalEvents();
	const { setHead } = useHead();
	const matchParams = useParams('category');

	const { id: categoryID, query: queryString } = match.params;
	
	const paramsCache = useRef(match.params);
	
	const [query] = useQuery(queryString, defaultQuery, undefined, undefined, childSelection, categoryID);
	
	const [filters, setFilters] = useState(null);
	const [pagination, setPagination] = useState(pageProps?.productData?.pagination || false);
	const [products, setProducts] = useState(pageProps?.productData?.products || false);
	const [tags, setTags] = useState(pageProps?.productData?.tags || false);
	const [banners, setBanners] = useState(pageProps?.banners || false);
	const [categorySchema, setCategorySchema] = useState(pageProps?.categorySchema || false);
	
	// Supporting Data
	const [recentProducts, setRecentProducts] = useState(false);
	const [activeCategory, setActiveCategory] = useState(pageProps?.productData?.category || false);
	
	const fetchedCategory = useRef(pageProps?.productData?.category ? categoryID : false);
	const fetchedQuery = useRef(pageProps?.query || false);

	const getData = (id, query) => {
		if(id) {
			setProducts(false);
			setTags(false);
			setActiveCategory(false);

			getCategoryProducts(id, query).then((payload) => {
				if (_mounted.current) {
					fetchedCategory.current = id;
					fetchedQuery.current = query;
					setPagination(payload.pagination);
					setProducts(payload.products);
					setActiveCategory(payload.category);
					setTags(payload.tags ? payload.tags : []);
				}
			}).catch((e) => {
				if (_mounted.current) {
					console.warn('Category products fetch error', e);
					if(e.status === 404) {
						setNotFound();
					}
					else {
						setError();
					}
				}
			})
		}
	};

	const getFilters = (id, inStock) => {
		setFilters(false);
		getCategoryFilters(id, inStock).then((payload) => {
			if (_mounted.current) {
				setFilters({ id: id, inStock: inStock, filters: payload});
			}
		}).catch((e) => {
			if (_mounted.current) {
				console.warn('Category filters fetch error', e);
				if(e.status === 404) {
					setNotFound();
				}
				else {
					setError();
				}
			}
		})
	}

	const updateQuery = useCallback((updatedQuery) => {
		let newQuery = [];

		for (const param in updatedQuery) {
			if (updatedQuery[param].length && defaultQuery[param] !== updatedQuery[param]) {
				if(param !== 'primaryVariable' && !(param === 'age_range' && childSelection.gender === config.listingPregnantGender && updatedQuery[param] === config.listingPregnantValue)){
					newQuery.push(param + '=' + updatedQuery[param]);
				}
			}
		}

		redirect(
			'category',
			{
				...paramsCache.current,
				query: (newQuery.length ? newQuery.join('&') : undefined),
			},
			undefined,
			undefined,
			false
		);
	}, [childSelection])

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, [])

	useEffect(() => {
		paramsCache.current = match.params;
	}, [match.params])
	
	useEffect(() => {
		if (_mounted.current && query?.primaryVariable) {
			const {primaryVariable, ...newQuery} = query;

			const idChanged = primaryVariable && primaryVariable !== fetchedCategory.current;
			
			if (!isExact(newQuery, fetchedQuery.current) || idChanged) {
				getData(primaryVariable, newQuery);

				if (idChanged) {
					setBanners(false);
					setPagination(false);
					setCategorySchema(null);
					getCategorySchema(primaryVariable).then((payload) => {
						setCategorySchema(payload);
					});

					getCategoryBanners(primaryVariable).then((payload) => {
						if(_mounted.current) {
							setBanners(payload);
						}
					}).catch((e) => {
						if (_mounted.current) {
							console.warn('Category banners fetch error', e);
							setBanners([])
						}
					})
				}
			}
		}
	}, [query])

	useEffect(() => {
		if(query.primaryVariable && filters !== false && !(filters?.id === query.primaryVariable && (query.stock === "1") === filters?.inStock)) {
			getFilters(query.primaryVariable, query.stock === "1");
		}
	}, [filters, query])

	useEffect(() => {
		if (_mounted.current && activeCategory && categorySchema !== null) {
			setHead(generateHead(activeCategory, pagination, categorySchema));
		}
	}, [activeCategory, pagination, categorySchema])

	// Supporting Data
	useEffect(() => {
		if (_mounted.current && !recentProducts) {
			getRecentProducts().then((payload) => {
				if (_mounted.current) {
					setRecentProducts(payload);
				}
			}).catch(() => {
			})
		}
	}, [recentProducts]);

	// Analytics
	useEffect(() => {
		if(activeCategory) {
			triggerEvent('listingDataUpdate', {
				key: `Kategori Sayfası: ${activeCategory.name}`,
				products,
				params: {
					taxonomy: activeCategory?.breadcrumb?.length ? activeCategory?.breadcrumb.map(category => category.name) : [activeCategory.name],
				},
			});
		}
	}, [products, activeCategory])


	return (
		<main className="page listing">
			<Breadcrumbs data={activeCategory.breadcrumb} />
			<Listing
				page="category"
				onUpdateQuery={updateQuery}
				banners={banners}
				query={query}
				products={products}
				pagination={pagination}
				filters={filters?.filters ?? false}
				title={activeCategory.name}
				suggestions={{
					title: false,
					items: tags,
				}}
				description={activeCategory.description} />
			<ProductCarousel
				tabs
				className="listing-recentproducts"
				data={[
					{
						title: 'Son Gezilen Ürünler',
						icon: 'history',
						key: 'recent',
						products: recentProducts ? recentProducts : false,
					}
				]} />
		</main>
	)
}

Category.getServerProps = ({ match, mediaStatus }) => {
	const categoryId = match.params.id;
	// const isMobile = mediaStatus?.mobile ?? false;

	const [query] = parseQuery(match.params.query, defaultQuery, undefined, undefined, undefined, categoryId);

	return new Promise((resolve) => {
		Promise.all([
			getCategoryProducts(categoryId, query),
			getCategoryBanners(categoryId),
			getCategorySchema(categoryId),
		]).then(([
			productData,
			banners,
			categorySchema,
		]) => {
			const head = productData?.products ? generateHead(productData.category, productData?.pagination, categorySchema) : undefined;

			resolve({
				pageProps: {
					query,
					productData,
					banners,
					categorySchema,
				},
				head,
			});
		}).catch((error) => {
			const status = error.status ?? 500;
			resolve({
				status: status,
				notFound: status === 404,
				error: status !== 404,
			})
		})
	});
}

Category.defaultProps = {
}

export default Category;